import {
  Button,
  Colors,
  MenuDivider,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { FGCustomInput, IFGFormGroupProps, useFGContext } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import DOMPurify from "dompurify";

import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";

const editorConfiguration = {
  toolbar: {
    items: [
      "mailEditorTypeAnswerButton",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "imageUpload",
      "blockQuote",
      "insertTable",
      "mediaEmbed",
      "undo",
      "redo",
      "alignment",
      "fontColor",
      "fontBackgroundColor",
      "findAndReplace",
      "fontSize",
      "highlight",
      "htmlEmbed",
      "subscript",
      "superscript",
      "underline",
      "strikethrough",
      "imageInsert",
      "horizontalLine",
      "sourceEditing",
    ],
  },
  language: "fr",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
      "linkImage",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
};

const Container = styled.div<{ height: string; width: string }>`
  display: flex;
  flex: 1;

  & .editor-container {
    flex: 3;
  }

  & .ck.ck-editor {
    width: ${(props) => props.width};
  }
  & .ck.ck-content {
    height: ${(props) => props.height};
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MiddleIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px ${Colors.LIGHT_GRAY3};
  padding: 0 0.5rem;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  & *:focus {
    outline: none !important;
  }s
`;

const MenuStyled = styled.div`
  background: transparent !important;
  flex: 1;
  & li {
    list-style-type: none;
  }
`;

export interface IFGMailEditorProps
  extends Omit<IFGFormGroupProps, "children"> {
  placeholder?: string;
  growVertically?: boolean;
  rows?: number;
  readonly?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  height?: string;
  loading?: boolean;
}

export const FGMailEditor: React.FunctionComponent<IFGMailEditorProps> = ({
  growVertically,
  rows,
  placeholder,
  readonly = false,
  disabled,
  tabIndex,
  loading,
  height,
  name,
  ...formGroupProps
}) => {
  const { apis, t } = useCommonHooks();
  const {
    formik,
    editMode,
    loading: globalLoading,
    disabled: globalDisabled,
  } = useFGContext();

  const { data: variables, isFetching: loadingVariables } = useQuery(
    "referential-variables",
    React.useCallback(
      async () => await apis.referentials.variables(),
      [apis.referentials]
    )
  );

  const finalData = React.useMemo(() => {
    if (formik && formik?.values) {
      const value = formik.values[name] as string;
      if (value) {
        return value;
      }
    }
    return "";
  }, [name, formik]);

  const [showVariablesPane, setShowVariablesPane] = React.useState(editMode);

  const onEditorChange = React.useCallback(
    (event, editor) => {
      const data = editor.getData();
      formik.setFieldValue(name, data);
    },
    [name, formik]
  );
  const editorInstance = React.useRef<any>();

  const writeAtCursor = React.useCallback((text: string) => {
    editorInstance.current.model.change((writer) => {
      const insertPosition =
        editorInstance.current.model.document.selection.getFirstPosition();
      writer.insertText(text, insertPosition);
    });
  }, []);

  const onAddFieldDialogClosed = React.useCallback(
    (value: string) => {
      const formattedValue = `{{${value}}}`;
      writeAtCursor(formattedValue);
    },
    [writeAtCursor]
  );

  const onEditorReady = React.useCallback((instance: any) => {
    editorInstance.current = instance;
  }, []);

  return (
    <Container height={height} width={showVariablesPane ? "580px" : "818px"}>
      {disabled || globalDisabled || readonly ? (
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(finalData) }}
        />
      ) : (
        <FGCustomInput
          name={name}
          noLabel
          className={"editor-container " + formGroupProps.className}
        >
          {(ctx) =>
            !globalLoading &&
            !loading && (
              <CKEditor
                editor={Editor}
                config={editorConfiguration}
                data={finalData}
                onBlur={onEditorChange}
                onReady={onEditorReady}
              />
            )
          }
        </FGCustomInput>
      )}
      {editMode && (
        <MenuContainer
          style={{
            flex: showVariablesPane ? 1 : null,
          }}
        >
          <MiddleIconContainer>
            <Button
              icon={showVariablesPane ? "chevron-right" : "chevron-left"}
              minimal
              onClick={() => setShowVariablesPane((prev) => !prev)}
              style={{ height: "100%" }}
            />
          </MiddleIconContainer>
          <MenuStyled
            style={{
              maxWidth: showVariablesPane ? "100%" : 0,
              visibility: showVariablesPane ? "visible" : "hidden",
            }}
          >
            <MenuDivider title={t(ETLCodes.Variables)} />
            {loadingVariables ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              variables?.map((v) => (
                <MenuItem
                  key={v.idValue}
                  text={v.displayValue}
                  onClick={(e) => onAddFieldDialogClosed(v.idValue)}
                />
              ))
            )}
          </MenuStyled>
        </MenuContainer>
      )}
    </Container>
  );
};

export default FGMailEditor;
