import {
  Card,
  Classes,
  Colors,
  Divider,
  Icon,
  InputGroup,
} from "@blueprintjs/core";
import { Classes as Classes2, Popover2 } from "@blueprintjs/popover2";
import { format } from "date-fns";
import * as React from "react";
import styled from "styled-components";

import { ActionCode, AskRequestDto } from "../../api";
import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { LinkButton, SendButton } from "../applicationButtons";
import { LoadingDots } from "../LoadingDots";

interface IAction {
  text: string;
  onClick: () => void;
}

interface IMessage {
  text: string;
  fromUser?: boolean;
  loading?: boolean;
  time?: Date;
  isError?: boolean;
  action?: IAction;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  right: 0;

  &
    .${Classes.CARD},
    .${Classes.POPOVER},
    .${Classes.POPOVER_CONTENT},
    .${Classes2.POPOVER2},
    .${Classes2.POPOVER2_CONTENT} {
    border-radius: 0.35rem !important;
    &.${Classes.ELEVATION_0} {
      box-shadow: 0px 0px 15px 0px rgba(82, 63, 105, 0.05) !important;
    }
  }
`;

const Circle = styled.div<{ bgcolor: string }>`
  height: 50px;
  width: 50px;
  background-color: ${(props) => props.bgcolor};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 0.5rem;
  cursor: pointer;
`;

const ChatContainer = styled(Card)`
  height: auto;
  width: 500px;
`;

const TitleContainer = styled.span`
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: large;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  flex-direction: column-reverse;

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #ffffff;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 10px;
    border: 3px solid #ffffff;

    &:hover {
      background-color: #787878;
    }
  }
`;

const MessageContainer = styled.div<{ who: "user" | "bot" }>`
  align-self: ${(props) => (props.who === "user" ? "flex-end" : "flex-start")};
  display: flex;
  flex-direction: column;
  margin: 1px;
  margin-bottom: 0.5rem;
`;

const Message = styled(Card)<{ bgcolor: string; color: string }>`
  display: flex;
  max-width: 250px;
  width: auto;
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  padding: 12px;
  align-items: center;

  & .action {
    margin-left: 0.2rem;
  }
`;

const WhoContainer = styled.span<{ who: "user" | "bot" }>`
  align-self: ${(props) => (props.who === "user" ? "flex-end" : "flex-start")};
  font-weight: bold;
`;

const TimeContainer = styled.span<{ who: "user" | "bot" }>`
  align-self: ${(props) => (props.who === "user" ? "flex-end" : "flex-start")};
  font-size: 8pt;
`;

export interface IChatBotProps {}

export const ChatBot: React.FunctionComponent<IChatBotProps> = (props) => {
  const { theme, t, apis, hasAction } = useCommonHooks();
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState<string>("");
  const inputRef = React.useRef<HTMLInputElement>();
  const [messages, setMessages] = React.useState<IMessage[]>([]);

  const reset = React.useCallback(() => setMessages([]), []);

  const addMessage = React.useCallback((newMessage: IMessage) => {
    setMessages((prevMessages) => [newMessage, ...prevMessages]);
  }, []);

  const triggerWelcomeMessage = React.useCallback(() => {
    addMessage({
      loading: true,
      text: t(ETLCodes.ChatBotWelcome),
      time: new Date(),
    });

    if (messageTimerRef.current) {
      clearTimeout(messageTimerRef.current);
      messageTimerRef.current = null;
    }
    messageTimerRef.current = setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages.map((m, i) => (i === 0 ? { ...m, loading: false } : m)),
      ]);
    }, 500);
  }, [addMessage, t]);

  React.useEffect(() => {
    if (messages.length > 0 || !isOpen) return;
    triggerWelcomeMessage();
  }, [isOpen, messages.length, triggerWelcomeMessage]);

  const updateLastMessage = React.useCallback((newValues: IMessage) => {
    setMessages((prevMessages) => [
      ...prevMessages.map((m, i) => (i === 0 ? { ...m, ...newValues } : m)),
    ]);
  }, []);

  const messageTimerRef = React.useRef<NodeJS.Timeout>();
  const sendMessage = React.useCallback(() => {
    addMessage({
      fromUser: true,
      text: message,
      time: new Date(),
    });

    setMessage("");

    if (messageTimerRef.current) {
      clearTimeout(messageTimerRef.current);
      messageTimerRef.current = null;
    }
    messageTimerRef.current = setTimeout(async () => {
      setLoading(true);
      addMessage({
        loading: true,
        text: "",
      });

      try {
        const res = await apis.chatbot.ask(
          new AskRequestDto({
            prompt: message,
            refreshCache: messages.length === 1,
          })
        );
        updateLastMessage({
          loading: false,
          text: res.answer,
        });
        if (res.expired) {
          updateLastMessage({
            loading: false,
            text: t(ETLCodes.YourSessionHasExpired),
            isError: true,
            action: {
              text: t(ETLCodes.Refresh),
              onClick: reset,
            },
          });
        }
      } catch (e) {
        console.error(e);
        updateLastMessage({
          loading: false,
          text: t(ETLCodes.AnErrorOccuredPleaseRefresh),
          isError: true,
        });
      } finally {
        setLoading(false);
        inputRef.current?.focus();
      }
    }, 500);
  }, [
    addMessage,
    apis.chatbot,
    message,
    messages.length,
    reset,
    t,
    updateLastMessage,
  ]);

  return (
    hasAction([ActionCode.ChatBot]) && (
      <Container>
        <Popover2
          isOpen={isOpen}
          position="left-top"
          usePortal={false}
          content={
            <ChatContainer>
              <TitleContainer>{t(ETLCodes.SmileAssistant)}</TitleContainer>
              <Divider />
              <MessagesContainer>
                {messages.map((m, i) => (
                  <MessageContainer
                    key={"message_" + i}
                    who={m.fromUser ? "user" : "bot"}
                  >
                    <WhoContainer who={m.fromUser ? "user" : "bot"}>
                      {m.fromUser ? t(ETLCodes.You) : t(ETLCodes.Assistant)}
                    </WhoContainer>
                    {m.isError ? (
                      <Message
                        elevation={1}
                        bgcolor={Colors.RED3}
                        color={"white"}
                      >
                        {m.text}
                        {!!m.action && (
                          <LinkButton
                            text={m.action.text}
                            onClick={m.action.onClick}
                            invertedColors
                            className="action"
                          />
                        )}
                      </Message>
                    ) : (
                      <Message
                        elevation={1}
                        bgcolor={m.fromUser ? theme.primaryColor : "white"}
                        color={m.fromUser ? "white" : "initial"}
                      >
                        {m.loading ? <LoadingDots size={5} /> : m.text}
                      </Message>
                    )}
                    {m.time && (
                      <TimeContainer
                        className={Classes.TEXT_MUTED}
                        who={m.fromUser ? "user" : "bot"}
                      >
                        {format(m.time, "HH:mm")}
                      </TimeContainer>
                    )}
                  </MessageContainer>
                ))}
              </MessagesContainer>
              <InputGroup
                key="chat-input"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                placeholder={t(
                  loading ? ETLCodes.AwaitingAnswer : ETLCodes.AskAQuestion
                )}
                disabled={loading}
                inputRef={inputRef}
                onKeyDown={(e) => e.key === "Enter" && sendMessage()}
                rightElement={
                  <SendButton
                    loading={loading}
                    minimal
                    onClick={() => sendMessage()}
                  />
                }
                autoFocus
              />
            </ChatContainer>
          }
        >
          <Circle
            onClick={() => setIsOpen((prev) => !prev)}
            bgcolor={theme.primaryColor}
          >
            <Icon icon="chat" color="white" size={20} />
          </Circle>
        </Popover2>
      </Container>
    )
  );
};

export default ChatBot;
